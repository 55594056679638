<template>
  <AuthLayout>
    <VFadeTransition mode="in-out">
      <VCol>
        <p class="text-h3 text-center" :class="{ 'text-h6': $vuetify.breakpoint.xs }">
          {{ $t("auth.titleRegistration") }}
        </p>
      </VCol>
    </VFadeTransition>
    <VSlideXTransition>
      <VAlert type="success" v-if="success" outlined style="background:white!important">
        {{ $t("auth.needToActivateAccount") }}
        <b>{{ enteredEmail || "" }}</b>
        <template slot="prepend">
          <activation-info />
        </template>
      </VAlert>
    </VSlideXTransition>

    <VForm @submit.prevent="onRegister">
      <VRow align="center">
        <VCol cols="12">
          <VAutocomplete
            v-model="user.uuid_deport"
            :items="deportList"
            item-text="name"
            item-value="uuid"
            :hide-details="!deportErrors.length"
            :error-messages="deportErrors"
            :label="$t('form.storage')"
            dense
            outlined
            background-color="white"
          >
          </VAutocomplete>
        </VCol>
        <VCol cols="12" sm="12">
          <VTextField
            outlined
            :label="$t('form.company')"
            v-model="user.company"
            dense
            background-color="white"
          />
        </VCol>
        <VCol cols="12" lg="6" md="6" sm="12">
          <VTextField
            outlined
            :label="$t('form.name')"
            v-model="user.name"
            :error-messages="nameErrors"
            v-on:keypress="noEngText($event)"
            dense
            background-color="white"
            hide-details="auto"
            @paste="noEngTextPasted($event)"
            :hint="onEngText ? '' : $t('form.hint.NameNoEngText')"
            @input="noEngText($event, false)"
          />
        </VCol>
        <VCol cols="12" lg="6" md="6" sm="12">
          <VTextField
            outlined
            :label="$t('form.surname')"
            v-model="user.surname"
            :error-messages="surnameErrors"
            v-on:keypress="noEngText($event)"
            dense
            background-color="white"
            hide-details="auto"
            @paste="noEngTextPasted($event)"
            :hint="onEngText ? '' : $t('form.hint.SurnameNoEngText')"
            @input="noEngText($event, false)"
          />
        </VCol>
       
        <VCol cols="12">
          <VTextField
            class="inputPhone"
            outlined
            :label="$t('form.phone_number')"
            v-model="user.phone"
            :error-messages="phoneErrors"
            :hide-details="!phoneErrors.length"
            dense
            prefix="+38"
            @paste="noEngTextPasted($event, true)"
            :maxLength="20"
            background-color="white"
            v-on:keypress="onlyNumber($event)"
          >
          </VTextField>
        </VCol>
        <VCol cols="5">
          <v-checkbox v-model="user.isCRM" :label="$t('form.crm_user')"   hide-details="auto"></v-checkbox>
        </VCol>
        <VCol cols="7">
          <VTextField
            outlined
            :label="$t('form.email')"
            v-model="user.email"
            :error-messages="emailErrors"
            :hide-details="!emailErrors.length"
            dense
            background-color="white"
          />
        </VCol>

        <VCol cols="12">
          <VTextField
            outlined
            :label="$t('form.password')"
            dense
            v-model="user.password"
            :type="passwordVisible ? 'text' : 'password'"
            :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="passwordVisible = !passwordVisible"
            :error-messages="passwordErrors"
            autocomplete="new-password"
            background-color="white"
            hide-details="auto"
          />
        </VCol>
        <VCol class="text-center" cols="12">
          <VBtn
            outlined
            color="success"
            class="br-10 bg-white"
            depressed
            large
            type="submit"
            :loading="loading"
            :disabled="loading"
            block
          >
            {{ $t("auth.btnRegister") }}
          </VBtn>

          <VRow class="mt-6" justify="center" no-gutters>
            <VCol cols="auto">
              <VBtn color="primary" text @click="$router.push('/')">
                {{ $t("auth.already_registered") }}
              </VBtn>
            </VCol>
          </VRow>
        </VCol>
      </VRow>
    </VForm>
  </AuthLayout>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import * as actionTypes from "@/store/modules/auth/types/actions";
import { mapActions, mapGetters } from "vuex";
import AuthLayout from "./AuthLayout.vue";
import lang from "../../mixins/language";
import notifications from "../../mixins/notifications";
import passwordMask from "../../validators/passwordMask";
import phoneMask from "../../validators/phoneMask";
import activationInfo from "./ActivationInfo.vue";
import onlyNumber from "../../mixins/onlyNumber";
import { noEngTextPasted, noEngText } from "../../mixins/helpers";
import noEngTextValidator from "@/validators/noEngText";

export default {
  name: "RegisterComponent",
  mixins: [lang, validationMixin, notifications, onlyNumber],
  components: {
    AuthLayout,
    activationInfo
  },
  validations: {
    user: {
      uuid_deport: { required },
      email: { required, email },
      password: { required, passwordMask },
      name: { required, noEngTextValidator },
      surname: { required, noEngTextValidator },
      phone: { required, phoneMask }
    }
  },
  data: () => ({
    onEngText: true,
    passwordVisible: false,
    success: false,
    loading: false,
    deportList: [],
    user: {
      uuid_deport: "",
      company: "",
      email: "",
      name: "",
      surname: "",
      phone: "",
      password: "",
      enteredEmail: "",
      isCRM: false,
    },
    datePikerDialog: false
  }),
  watch: {},
  methods: {
    noEngText,
    noEngTextPasted,
    ...mapActions("auth", {
      register: actionTypes.REGISTER,
      requestDeports: actionTypes.DEPORTS
    }),
    async onRegister() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.loading = true;
          const formData = new FormData();
          formData.append("name", this.user.name);
          formData.append("uuid_deport", this.user.uuid_deport);
          formData.append("surname", this.user.surname);
          formData.append("company", this.user.company);
          formData.append("email", this.user.email);
          formData.append("phone", `+38${this.user.phone}`);
          formData.append("password", this.user.password);
          formData.append("lang", this.currentLanguage.lang);
          formData.append("type", this.user.isCRM ? "crm" : "client");
          if (this.user.date_crm_end) {
            formData.append("date_crm_end", this.user.date_crm_end);
          }
          formData.append("url_landing", `https://${window.location.host}/activation/`);
          await this.register(formData);
          this.setSuccessNotification(this.$t("auth.successfulRegistration"));
          this.enteredEmail = this.user.email;
          this.clearUser();
          this.success = true;
          this.$v.$reset();
          this.loading = false;
        } catch (e) {
          this.loading = false;
          this.success = false;
          this.setErrorNotification(this.$t("auth.userAlreadyExist"));
        }
      }
    },
    clearUser() {
      Object.keys(this.user).forEach(key => {
        this.user[key] = "";
      });
    }
  },
  computed: {
    ...mapGetters(["currentLanguage"]),
    deportErrors() {
      const errors = [];
      if (!this.$v.user.uuid_deport.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.uuid_deport.required && errors.push(this.$t("form.errors.ChoseDeport"));
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.user.email.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.email.required && errors.push(this.$t("form.errors.EmailRequired"));
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.email.email && errors.push(this.$t("form.errors.EmailMustBeValid"));
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.user.password.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.password.required && errors.push(this.$t("form.errors.PasswordRequired"));
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.password.passwordMask && errors.push(this.$t("form.errors.PasswordMustBeLike"));
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.user.name.$dirty) {
        return errors;
      }
      if (!this.$v.user.name.noEngTextValidator) {
        errors.push(this.$t("form.hint.NameNoEngText"));
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.name.required && errors.push(this.$t("form.errors.NameRequired"));
      return errors;
    },
    surnameErrors() {
      const errors = [];
      if (!this.$v.user.surname.$dirty) {
        return errors;
      }
      if (!this.$v.user.surname.noEngTextValidator) {
        errors.push(this.$t("form.hint.SurnameNoEngText"));
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.surname.required && errors.push(this.$t("form.errors.SurnameRequired"));
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.user.phone.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.phone.required && errors.push(this.$t("form.errors.PhoneNumberRequired"));
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.phone.phoneMask && errors.push(this.$t("form.errors.PhoneNumberMustBeLike"));
      return errors;
    }
  },
  async mounted() {
    try {
      const data = await this.requestDeports();
      // orderDialog('data', data)
      this.deportList = data.data.object;
      if (!this.deportList) {
        this.deportList = [];
      }
    } catch (e) {
      this.setErrorNotification(e);
    }
  }
};
</script>

<style scoped></style>
