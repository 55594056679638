<template>
  <RegisterComponent />
</template>

<script>
import RegisterComponent from '../components/auth/RegisterComponent.vue';

export default {
  name: 'Register',
  components: {
    RegisterComponent,
  },
};
</script>

<style>
body,html{
  overflow: unset!important;
}
</style>
